import {inject, Injectable} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {Client, createClient} from 'graphql-ws';
import {environment} from '../../environments/environment';
import {skip, Subject} from 'rxjs';
import {AppService} from '../app.service';
import {LoggerService} from '../logger.service';

@Injectable({providedIn: 'root'})
export class WsClient {
  private readonly logger = inject(LoggerService).create('Websocket');
  private client: Client;

  private connectSubject = new Subject<void>();
  onConnect = this.connectSubject.asObservable();
  onReconnect = this.onConnect.pipe(
    skip(1),
  );

  constructor(private readonly authService: AuthService,
              private readonly appService: AppService) {
  }

  create() {
    let activeSocket: WebSocket;
    let timedOut: ReturnType<typeof setTimeout>;
    this.client = createClient({
      url: environment.socketUrl,
      retryWait: async () => {
        await new Promise((resolve) => setTimeout(resolve, 1000 + Math.random() * 1000));
      },
      shouldRetry: (event: Event) => event.type === 'close' || event.type === 'error',
      retryAttempts: Infinity,
      lazy: true,
      keepAlive: 3000,
      on: {
        connected: (socket: WebSocket) => (activeSocket = socket),
        ping: (received) => {
          if (!received) {
            timedOut = setTimeout(() => {
              if (activeSocket?.readyState === WebSocket.OPEN) {
                activeSocket.close(4408, 'Request Timeout');
                this.logger.logWarning('Timeout: connection closed');
              }
            }, 1500);
          }
        },
        pong: (received) => {
          if (received) {
            clearTimeout(timedOut);
          }
        },
      },
      connectionParams: () => ({
        authToken: this.authService.accessToken,
        clientId: this.authService.clientId,
        clientType: this.appService.clientType,
        appId: this.appService.appId,
      }),
    });

    this.client.on('connected', () => {
      this.logger.logInfo('Connected');
      this.connectSubject.next();
    });

    return this.client;
  }
}
